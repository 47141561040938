<template>
	<section class="contentblocks-slider-section">
		<client-only>
			<bc-carousel ref="carousel" :items-to-show="1" class="contentblocks-slider">
				<bc-slide v-for="item in filteredItems" :key="item.ID" class="contentblock-item slider-item">
					<div class="contentblock-item">
						<div class="row">
							<div class="columns column6 align-left">
								<h2>{{ item.title || item.header }}</h2>
								<div class="inner-content">
									<strong v-if="item.subtitle" class="subtitle">
										{{ item.subtitle }}
									</strong>
									<div v-parse-links v-html="item.content" />
								</div>
							</div>
						</div>
						<div class="image">
							<picture>
								<source :srcset="item.imageWebp" type="image/webp" />
								<source :srcset="item.image" />
								<img :src="item.image" :alt="item.imageAlt" loading="lazy" />
							</picture>
						</div>
					</div>
				</bc-slide>

				<template #addons>
					<div v-if="filteredItems.length > 1" class="custom-pagination">
						<bc-pagination />
					</div>
				</template>
			</bc-carousel>
		</client-only>
	</section>
</template>

<script setup>
const props = defineProps({
	items: { type: Array, default: () => [] },
});

const filteredItems = computed(() => props.items.filter((item) => !!item.image));
</script>

<style lang="scss" scoped>
.contentblocks-slider-section {
	padding: 0;
}

.carousel {
	padding: 0;

	h2 {
		max-width: 330px;
		margin: 0 0 10px;
		color: var(--cta-color-hover);
	}

	.column6 {
		padding: 110px 140px;
	}

	.inner-content {
		margin: 60px 0 0;
	}
}

.contentblock-item {
	background: var(--header-color);
	width: 100%;
	color: #fff;

	:deep(.button) {
		color: #fff;
		border-color: #fff;

		&:hover {
			color: var(--body-color);
			border-color: var(--cta-color);
		}
	}

	.image {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 50%;

		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}
}

/* stylelint-disable selector-class-pattern */
.carousel__pagination {
	position: absolute;
	bottom: 30px;
	left: 0;
	right: 0;
	z-index: 3;

	:deep(li) {
		margin: 0 6px;
	}

	:deep(.carousel__pagination-button) {
		width: 14px;
		height: 14px;
		border: 2px solid #fff;
		background: rgba(255 255 255 / 0%);
		border-radius: 50%;
		transition: all 0.3s ease-in-out;

		&::after {
			display: none;
		}

		&:hover,
		&.carousel__pagination-button--active {
			background: rgba(255 255 255 / 100%);
		}
	}
}

.carousel__viewport {
	width: 100%;
}

:deep(.slider-navigation) {
	position: absolute;
	bottom: 0;
	left: 0;
	top: unset;
	transform: unset;
	width: 67px;
	height: 67px;
	margin: 0;
	padding: 0;

	&.carousel__next {
		left: unset;
		right: 0;
	}
}
/* stylelint-enable selector-class-pattern */

.subtitle {
	text-transform: uppercase;
}

:deep(p) {
	margin: 0 0 50px;
}

@media (max-width: 1380px) {
	.carousel {
		.column6 {
			padding: 90px;
		}
	}
}

@media (max-width: 1080px) {
	:deep(p) {
		margin: 0 0 25px;
	}

	.contentblock-item {
		padding: 20px 0;

		.image {
			position: relative;
			top: unset;
			right: unset;
			bottom: unset;
			width: 100%;
			padding: 0 40px 40px;
		}

		.column6 {
			padding: 50px 40px;

			:deep(p:last-child) {
				margin-bottom: 0;
			}
		}
	}
}
</style>
